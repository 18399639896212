<template>
  <div class="module-category-wrap">
    <TwoNavBar></TwoNavBar>
    <div class="content">
      <router-view name="ielts" :key="$route.fullPath"></router-view>
      <router-view name="toefl" :key="$route.fullPath"></router-view>
      <router-view name="gmat" :key="$route.fullPath"></router-view>
      <router-view name="gre" :key="$route.fullPath"></router-view>
      <router-view name="pte" :key="$route.fullPath"></router-view>
      <router-view name="duolinguo" :key="$route.fullPath"></router-view>
      <router-view name="practical" :key="$route.fullPath"></router-view>
      <router-view name="japanese" :key="$route.fullPath"></router-view>
      <router-view name="alevel" :key="$route.fullPath"></router-view>
    </div>
    <float-bar category="ielts"></float-bar>
    <FloatAds ref="floatAdsRef" @adsRes="adsRes" :otherAdsPosition="otherAdsPosition" :category_id="categoryId" :unAutoAcquire="true"></FloatAds>
    <template v-if="adDialogConfig.type">
      <AdDialog ref="adDialogRef" :adDialogConfig='adDialogConfig' :unAutoAcquire="true"/>
    </template>

  </div>
</template>

<script>
  import CategoryIndex from './CategoryWrap'

  export default CategoryIndex
</script>

<style lang="less" scoped>
  .module-category-wrap {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    .content{
      width: 100%;
      height: 100%;
      //position: absolute;
      //top: 69px;
      //left: 0;
    }
  }
</style>
