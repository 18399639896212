<template>
  <div style="background-color: #fff;">
    <div class="twoNavBar">
      <div class="navbar_title">
        <span :class="[itemIndex === index ? 'font-color' : '']" v-for="(item, index) in menuList" :key="item.url" @click="toItemUrl(item.url, index)">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoNavBar',
  data() {
    return {
      menuList: [],
      itemIndex: 0,
      ieltsMenu: [
          {
            url: '/ielts/index',
            name: '首页',
          },
          {
            url: '/ielts/community',
            name: '公益课',
          },
          {
            url: '/ielts/activity',
            name: '训练营',
          },
          {
            url: '/ielts/series',
            name: '课程商城',
          },
          {
            url: '/ielts/spoken',
            name: '口语素材',
          },
          {
            url: '/ielts/material',
            name: '资料中心',
          },
          {
            url: '/ielts/article',
            name: '精品文章',
          },
          // {
          //   url: '/ielts/prediction',
          //   name: '机经预测',
          //   tag: 'new'
          // }
        ],
        toeflMenu: [
          {
            url: '/toefl/index',
            name: '首页',
          },
          {
            url: '/toefl/community',
            name: '公益课',
          },
          {
            url: '/toefl/activity',
            name: '训练营',
          },
          {
            url: '/toefl/series',
            name: '课程商城',
          },
          {
            url: '/toefl/material',
            name: '资料中心',
          },
          {
            url: '/toefl/article',
            name: '精品文章',
          },
          // {
          //   url: '/ielts/prediction',
          //   name: '机经预测',
          //   tag: 'new'
          // }
        ],
        GMATMenu: [
          {
            url: '/gmat/index',
            name: '首页',
          },
          {
            url: '/gmat/community',
            name: '公益课',
          },
          {
            url: '/gmat/activity',
            name: '训练营',
          },
          {
            url: '/gmat/series',
            name: '课程商城',
          },
          {
            url: '/gmat/material',
            name: '资料中心',
          },
          {
            url: '/gmat/article',
            name: '精品文章',
          },
          // {
          //   url: '/ielts/prediction',
          //   name: '机经预测',
          //   tag: 'new'
          // }
        ],
        practicalMenu: [

          {
            url: '/practical/series',
            name: '课程商城',
          },
          {
            url: '/practical/material',
            name: '资料中心',
          },
          {
            url: '/practical/article',
            name: '精品文章',
          },
        ],
        japaneseMenu: [

          {
            url: '/japanese/index',
            name: '首页',
          },
          {
            url: '/japanese/community',
            name: '公益课',
          },
          {
            url: '/japanese/activity',
            name: '训练营',
          },
          {
            url: '/japanese/series',
            name: '课程商城',
          },
          {
            url: '/japanese/material',
            name: '资料中心',
          },
          {
            url: '/japanese/article',
            name: '精品文章',
          },
        ],
        GREMenu: [
          {
            url: '/gre/index',
            name: '首页',
          },
          {
            url: '/gre/community',
            name: '公益课',
          },
          {
            url: '/gre/series',
            name: '课程商城'
          },
          {
            url: '/gre/material',
            name: '资料中心'
          },
          {
            url: '/gre/article',
            name: '精品文章',
          },

        ],
        PTEMenu: [
          {
            url: '/pte/index',
            name: '首页',
          },
          {
            url: '/pte/community',
            name: '公益课',
          },
          {
            url: '/pte/series',
            name: '课程商城'
          },
          {
            url: '/pte/material',
            name: '资料中心'
          },
          {
            url: '/pte/article',
            name: '精品文章',
          }
        ],
      ALevelMenu: [
          {
            url: '/alevel/index',
            name: '首页',
          },

          {
            url: '/alevel/series',
            name: '课程商城'
          },

        ]
    }
  },
  created() {
    console.log('=>(TwoNavBar.vue:254) ', );
    this.$bus.$on('fn', this.foo)
  },
  mounted() {
    this.itemIndex = this.menuList.findIndex(item => item.url === this.$route.path)
  },
  beforeDestroy() {
    this.$bus.$off('fn')
  },
  methods: {
    foo(val) {
      console.log(val.categoryId, '点击一级菜单')
      this.itemIndex = 0
      if(val.url === '/practical') {
        this.$router.push({
          path: `${val.url}/series`
        })
      }else {
        this.$router.push({
          path: `${val.url}/index`
        })
      }
      switch (val.categoryId) {
          case 1:
            return this.menuList = this.ieltsMenu
          case 2:
            return this.menuList = this.toeflMenu
          case 4:
            return this.menuList = this.GMATMenu
          case 5:
            return this.menuList = this.practicalMenu
          case 3:
            return this.menuList = this.GREMenu
          case 6:
            return this.menuList = this.PTEMenu
        case 10:
            return this.menuList = this.ALevelMenu
          case 11:
            return this.menuList = this.japaneseMenu
          default:
            return this.menuList = []
        }
    },
    toItemUrl(url, index) {
      this.itemIndex = index
      this.$router.push({
        path: url
      })
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        if(newVal.path.split('/')[1] === 'ielts') {
          this.menuList = this.ieltsMenu
        }else if(newVal.path.split('/')[1] === 'toefl') {
          this.menuList = this.toeflMenu
        }else if(newVal.path.split('/')[1] === 'gmat') {
          this.menuList = this.GMATMenu
        }else if(newVal.path.split('/')[1] === 'practical') {
          this.menuList = this.practicalMenu
        }else if(newVal.path.split('/')[1] === 'gre') {
          this.menuList = this.GREMenu
        }else if(newVal.path.split('/')[1] === 'pte') {
          this.menuList = this.PTEMenu
        }else if(newVal.path.split('/')[1] === 'alevel') {
          this.menuList = this.ALevelMenu
        }else if(newVal.path.split('/')[1] === 'japanese') {
          this.menuList = this.japaneseMenu
        }
      },
      immediate: true
    }
  }

}
</script>

<style lang="less" scoped>
  .font-color {
    color: #29D087;
  }
  .twoNavBar {
    position: relative;
    width: 1200px;
    height: 48px;
    margin: 0 auto;
    background-color: #fff;
    .navbar_title {
      position: absolute;
      top: 5px;
      left: 220px;
      display: flex;
      height: 48px;
      font-size: 14px;
      span {
        line-height: 48px;
        margin-right: 28px;

      }
      &:hover{
        cursor: pointer;
      }
    }
  }
</style>
