import { render, staticRenderFns } from "./CategoryWrap.vue?vue&type=template&id=660aedbb&scoped=true"
import script from "./CategoryWrap.vue?vue&type=script&lang=js"
export * from "./CategoryWrap.vue?vue&type=script&lang=js"
import style0 from "./CategoryWrap.vue?vue&type=style&index=0&id=660aedbb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660aedbb",
  null
  
)

export default component.exports