import TwoNavBar from '@/components/NavBar/modules/TwoNavBar.vue'
import FloatBar from '@/components/FloatBar/FloatBar.vue'
import FloatAds from '@/components/FloatAds/FloatAds.vue'
import { adsPosition, adsTypePostion } from '@/enums/API.js'
import AdDialog from '@/components/AdDialog/AdDialog.vue'
import { useEventBus } from '@/utils/bus.js'



export default {
    name: 'CategoryWrap',
    data() {
        return {
            categoryId: 0,
            matchedName:'',
            otherAdsPosition:[adsPosition.OfficialWebsitePopUp],
            adDialogConfig:{
                type:'',
                config:{}
            },
            eventBus:undefined
        }
    },
    components: {
        TwoNavBar,
        FloatBar,
        FloatAds,
        AdDialog
    },
    created(){

        this.eventBus = useEventBus()
        this.eventBus.$on('busGetAd',(adParams)=>{
            this.matchedName = this.$route.matched[0].name
            this.judgeType()
            this.otherAdsPosition = [...this.otherAdsPosition, ...adParams.position]
            this.categoryId = adParams.category_id
            this.$nextTick(()=>{
                this.$refs.floatAdsRef?.init()
                this.$refs.adDialogRef?.init()
            })
        })
        window.addEventListener('beforeunload', (event) => {
            //刷新的时候清除分类下的广告位缓存
            sessionStorage.removeItem('FLOATADSCLASSIFY')
          });
    },
    beforeDestroy(){
        this.eventBus.$off('busGetAd')
    },
    methods: {
        judgeType(){
            this.adDialogConfig.type = ''
            this.adDialogConfig.config = {}
            switch(this.matchedName){
                case "ielts":
                    this.adDialogConfig.type = adsTypePostion.IELTSPopUp
                break;
                case 'toefl':
                    this.adDialogConfig.type = adsTypePostion.TOEFLPopUp
                break;
                case 'gre':
                    this.adDialogConfig.type = adsTypePostion.GREPopUp
                break;
                case 'gmat':
                    this.adDialogConfig.type = adsTypePostion.GMATPopUp
                break;
                case 'pte':
                    this.adDialogConfig.type = adsTypePostion.PTEPopUp
                break;
                case 'practical':
                    this.adDialogConfig.type = adsTypePostion.PracticalEnglishPopUp
                break;
              case 'alevel':
                // this.adDialogConfig.type = adsTypePostion.
                break;
            }
        },
        adsRes(obj){
            this.adDialogConfig.config = obj[adsPosition.OfficialWebsitePopUp][0] || {}
            this.eventBus.$emit('busAdCallback',obj)
        }
    }
}
